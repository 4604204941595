<template>
    <div style="position:relative;"
         class="document-input"
         :class="{'small': small, 'date-input-button elevation-2': button_style, 'date-input-filled': filled, 'req-star': !local_value && required}"
         :style="`${outerStyle}}`"
    >
        <div class="label" v-if="label && !button_style"
             :class="{'label-error': !local_value && required}"
        >
            {{ label }}
        </div>
        <div style="position:relative;" class="not-label">
          <div v-if="label && button_style" class="primary--text text--darken-1 font-weight-bold button-label">
            {{ label }}
          </div>
            <input type="text"
                   :class="{'error-input': !local_value && required}"
                   :value="local_value"
                   :disabled="disabled"
                   :maxlength="time ? 19 : 10"
                   :placeholder="time ? 'дд.мм.рррр 00:00:00' : 'дд.мм.рррр'"
                   :style="`min-height: ${minHeight}px; ${transparent ? 'background-color: transparent !important;' : ''} ${innerStyle}`"
                   @input="validInput"
                   @keydown="validKeyDown"
                   @change="validChange"
                   @focusout="validChange"
            >
            <div class="input-icon">
                <DateComponentIcon v-model="date_value" :row_num="row_num" :monthly="false" :disable="disabled"
                                   @changeDate="changeDate"/>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "document_date_input",
    props: {
      field: {
          type: String,
          default() {
              return ''
          }
      },
      value: {
          type: String,
          default: ''
      },
      row_num: {
          type: Number,
          default: 0
      },
      required: {
          type: Boolean,
          default: true
      },
      minHeight: {
          type: Number,
          default: 28
      },
      label: {
          type: String,
          default: ''
      },
      time: {
          type: Boolean,
          default: false
      },
      disabled: {
          type: Boolean,
          default: false
      },
      small: {
          type: Boolean,
          default: false,
      },
      transparent: {
          type: Boolean,
          default: false,
      },
      innerStyle: {
          type: String,
          default: ''
      },
      outerStyle: {
        type: String,
        default: ''
      },
      month_type: {
          type: Boolean,
          default: false
      },
      button_style: {
        type: Boolean,
        default: false,
      },
      filled: {
        type: Boolean,
        default: false,
      }
    },
    components: {
        DateComponentIcon: () => import("@/components/tableComponent/DateComponentIcon"),
    },
    data() {
        return {
            local_value: this.value,
            date_value: null,
            active: false
        }
    },
    methods: {
        changeDate(payload) {
            const value = payload.value
            const local_value = this.setLocalValue(value)
            const date_value = this.getDateValue(local_value)
            this.local_value = local_value
            this.$emit('input', date_value)
            this.$emit('inputValue',
                {
                    field: this.field,
                    text: local_value,
                    name: 'selectedRow'
                }
            )
            this.date_value = date_value
            this.$emit('userChange', {row_num: this.row_num, value: this.date_value})
        },
        getNumberInputValue(text) {
            return text.replace(/\D/g, '').substring(0, 14)
        },
        formatNumberToFormattedDate(value) {
            let numberVal = "" + value,
                day_group = '',
                month_group = '',
                year_group = '',
                hour_group = '',
                minute_group = '',
                second_group = '',
                formattedValue = ''

            day_group = numberVal.substring(0, 2)
            month_group = numberVal.substring(2, 4)
            year_group = numberVal.substring(4, 8)
            hour_group = numberVal.substring(8, 10)
            minute_group = numberVal.substring(10, 12)
            second_group = numberVal.substring(12, 14)

            if (numberVal.length >= 2) {
                if (+day_group <= 31) {
                    formattedValue = day_group + '.' + numberVal.substring(2)
                } else {
                    formattedValue = ''
                    numberVal = ''
                }
            }
            if (numberVal.length >= 4) {
                if (+month_group <= 12) {
                    formattedValue = formattedValue.substring(0, 3) + month_group + '.' + numberVal.substring(4)
                } else {
                    formattedValue = ''
                    numberVal = ''
                }
            }
            if (numberVal.length >= 8) {
                if (+year_group >= 1910 && +year_group <= 3000) {
                    if (this.time) {
                        formattedValue = formattedValue.substring(0, 6) + year_group + ' ' + numberVal.substring(8)
                    } else {
                        formattedValue = formattedValue.substring(0, 6) + year_group
                    }
                } else {
                    formattedValue = ''
                    numberVal = ''
                }
            }
            if (this.time) {
                if (numberVal.length >= 10) {
                    if (+hour_group <= 24) {
                        formattedValue = formattedValue.substring(0, 11) + hour_group + ':' + numberVal.substring(10)
                    } else {
                        formattedValue = ''
                        numberVal = ''
                    }
                }
                if (numberVal.length >= 12) {
                    if (+minute_group <= 59) {
                        formattedValue = formattedValue.substring(0, 14) + minute_group + ':' + numberVal.substring(12)
                    } else {
                        formattedValue = ''
                        numberVal = ''
                    }
                }
                if (numberVal.length >= 14) {
                    if (+second_group <= 59) {
                        formattedValue = formattedValue.substring(0, 17) + second_group
                    } else {
                        formattedValue = ''
                        numberVal = ''
                    }
                }
            }
            return {
                newNumberVal: numberVal,
                formattedValue
            }
        },
        validInput(e) {
            let val = e.target.value,
                input = e.target,
                numberVal = this.getNumberInputValue(val)

            if (!numberVal.length) {
                input.value = ''
                this.local_value = ''
                return;
            }

            if (input.value.length !== input.selectionStart) {
                this.local_value = val
                return;
            }

            const formatted = this.formatNumberToFormattedDate(numberVal)

            this.local_value = formatted.formattedValue
        },
        validByPosition(value, position, prev_value) {
            if (!value) return false
            let validate_object
            if (this.time) {
                validate_object = {
                    1: {number: 3, simple: true},
                    2: {number: 31, simple: false},
                    3: {number: 0, simple: true},
                    4: {number: 1, simple: true},
                    5: {number: 12, simple: false},
                    6: {number: 0, simple: true},
                    7: {number: 9, simple: true},
                    8: {number: 9, simple: true},
                    9: {number: 9, simple: true},
                    10: {number: 9, simple: true},
                    11: {number: 0, simple: true},
                    12: {number: 2, simple: true},
                    13: {number: 23, simple: false},
                    14: {number: 0, simple: true},
                    15: {number: 5, simple: true},
                    16: {number: 59, simple: false},
                    17: {number: 0, simple: true},
                    18: {number: 5, simple: true},
                    19: {number: 59, simple: false},
                }
            } else {
                validate_object = {
                    1: {number: 3, simple: true},
                    2: {number: 31, simple: false},
                    3: {number: 0, simple: true},
                    4: {number: 1, simple: true},
                    5: {number: 12, simple: false},
                    6: {number: 0, simple: true},
                    7: {number: 9, simple: true},
                    8: {number: 9, simple: true},
                    9: {number: 9, simple: true},
                    10: {number: 9, simple: true}
                }
            }

            const validate_row = validate_object[position]
            if (!validate_row) return
            if (validate_row.simple) {
                return (+value <= validate_row.number)
            } else {
                return (+(`${prev_value}${value}`) <= validate_row.number)
            }
        },
        validKeyDown(e) {
            const input = e.target
            const value = e.target.value
            const current_position = e.target.selectionStart
            const prev_position = current_position - 2
            const check = ['.', ':', ' ']
            const current_symbol = value.substring(prev_position + 1, prev_position + 2)
            const symbols = ['ArrowLeft', 'ArrowRight', 'Control']
            const combo = ['KeyV', 'KeyA']
            const string_key = String(e.key)
            const max_length = this.time ? 19 : 10
            const prev_value = value.substring(current_position - 1, current_position)


            if (e.keyCode === 8) {
                if (check.includes(current_symbol)) {
                    input.selectionStart = prev_position + 1
                    input.selectionEnd = prev_position + 1
                    e.preventDefault()
                    if (current_symbol === ':' || current_symbol === ' ') {
                        e.target.value = e.target.value.substring(0, prev_position + 2) + "00" + e.target.value.substring(prev_position + 2)
                        this.local_value = e.target.value
                        input.selectionStart = prev_position + 1
                        input.selectionEnd = prev_position + 1
                    }
                }
            } else {
                if (/\D/g.test(string_key) && !symbols.includes(string_key) && !combo.includes(e.code)) {
                    e.preventDefault()
                } else {
                    if (value.length === max_length && !(/\D/g.test(string_key))) {
                        let addToPosition = 0

                        if (current_position === 0) {
                            if (this.validByPosition(string_key, current_position + 1, prev_value)) {
                                e.target.value = string_key + value.substring(current_position + 1)
                                addToPosition = 1
                            } else {
                                e.preventDefault()
                                addToPosition = 0
                            }
                        } else {
                            const symbol = value.substring(current_position, current_position + 1)
                            if (check.includes(symbol)) {
                                e.preventDefault()
                                if (this.validByPosition(string_key, current_position + 2, prev_value)) {
                                    e.target.value = value.substring(0, current_position + 1) + string_key + value.substring(current_position + 2)
                                    addToPosition = 2
                                } else {
                                    addToPosition = 0
                                }
                            } else {
                                if (this.validByPosition(string_key, current_position + 1, prev_value)) {
                                    e.target.value = value.substring(0, current_position) + string_key + value.substring(current_position + 1)
                                    addToPosition = 1
                                } else {
                                    e.preventDefault()
                                    addToPosition = 0
                                }
                            }
                        }
                        input.selectionStart = current_position + addToPosition
                        input.selectionEnd = current_position + addToPosition
                        this.local_value = e.target.value
                    }
                }
            }
        },
        validChange(e) {
            const value = e.target.value
            e.target.value = this.validateFormattedDate(value)
            this.local_value = e.target.value
            this.$nextTick(() => {
                this.setDateValue()
                this.$emit('input', this.date_value)
                this.$emit('inputValue',
                    {
                        field: this.field,
                        text: this.local_value,
                        name: 'selectedRow'
                    },
                    this.$emit('userChange', {row_num: this.row_num, value: this.date_value})
                )
            })
        },
        validateFormattedDate(date) {
            if (!date) return ""
            const splitted = date.split(' ')
            const local_date = (splitted[0] || "")
            let local_time = (splitted[1] || "").trim().replace(/\D/g, "").trim()

            if (!local_time) {
                local_time = '000000'
            }
            if (local_time === "") {
                local_time = '000000'
            }

            let day = local_date.substring(0, 2)
            let month = local_date.substring(3, 5)
            let year = local_date.substring(6, 10)
            let hour = local_time.substring(0, 2)
            let minute = local_time.substring(2, 4)
            let seconds = local_time.substring(4, 6)

            const current_date = new Date()

            if (day.length === 1 && +day <= 9) {
                day = '0' + day
            }
            if (!month) {
                month = `${current_date.getMonth() + 1}`
            }
            if (month.length === 1 && +month <= 9) {
                month = '0' + month
            }
            if (+month > 12) {
                month = '12'
            }
            if (!year) {
                year = `${current_date.getFullYear()}`
            }
            if (+year < 100) {
                year = String(2000 + (+year))
            }
            if (+year < 1910) {
                year = '1910'
            }
            if (+year > 3000) {
                year = `${current_date.getFullYear()}`
            }
            if (!hour) {
                hour = '00'
            }
            if (!minute) {
                minute = '00'
            }
            if (!seconds) {
                seconds = '00'
            }

            if (!day || !month || !year) {
                return ""
            }

            let valid = false
            let formatted_date = ''
            let new_date = new Date(+year, +(month) - 1, +day, +hour, +minute, +seconds)
            if (new_date.getFullYear() === +year &&
                new_date.getMonth() + 1 === +month &&
                new_date.getDate() === +day) {
                valid = true
            }
            if (this.time) {
                formatted_date = `${day}.${month}.${year} ${hour}:${minute}:${seconds}`
            } else {
                formatted_date = `${this.month_type ? '01' : day}.${month}.${year}`
            }
            if (!valid) {
                formatted_date = ""
            }
            return formatted_date

        },
        setDateValue() {
            if (!this.local_value) {
                this.date_value = null
                return
            }
            if (this.local_value === "") {
                this.date_value = null
                return;
            }
            const splitted = this.local_value.split(' ')
            const local_date = (splitted[0] || "")
            const local_time = (splitted[1] || "").trim().replace(/\D/g, "")
            let day = local_date.substring(0, 2)
            let month = local_date.substring(3, 5)
            let year = local_date.substring(6, 10)
            let hour = local_time.substring(0, 2)
            let minute = local_time.substring(2, 4)
            let seconds = local_time.substring(4, 6)

            if (this.time) {
                this.date_value = `${year}-${month}-${day} ${hour}:${minute}:${seconds}.000000`
            } else {
                this.date_value = `${year}-${month}-${this.month_type ? '01' : day}`
            }
        },
        getDateValue(payload) {
            if (!payload) return null
            if (payload === "") return null
            const splitted = payload.split(' ')
            const local_date = (splitted[0] || "")
            const local_time = (splitted[1] || "").trim().replace(/\D/g, "")
            let day = local_date.substring(0, 2)
            let month = local_date.substring(3, 5)
            let year = local_date.substring(6, 10)
            let hour = local_time.substring(0, 2)
            let minute = local_time.substring(2, 4)
            let seconds = local_time.substring(4, 6)

            if (this.time) {
                return `${year}-${month}-${day} ${hour}:${minute}:${seconds}.000000`
            } else {
                return `${year}-${month}-${this.month_type ? '01' : day}`
            }
        },
        setLocalValue(date) {
            if (!date) return ""
            if (date === "") return ""
            const splitted = date.replace('T', ' ').split(' ')
            const local_date = (splitted[0] || "")
            let local_time = (splitted[1] || "").trim().replace(/\D/g, "").substring(0, 9)
            if (!local_time) {
                local_time = "000000"
            }
            if (local_time === "") {
                local_time = "000000"
            }
            let year = local_date.substring(0, 4)
            let month = local_date.substring(5, 7)
            let day = local_date.substring(8, 10)
            let hour = local_time.substring(0, 2)
            let minute = local_time.substring(2, 4)
            let seconds = local_time.substring(4, 6)

            if (this.time) {
                return `${day}.${month}.${year} ${hour}:${minute}:${seconds}`
            } else {
                return `${this.month_type ? '01' : day}.${month}.${year}`
            }
        },
    },
    watch: {
        value: {
            immediate: true,
            deep: false,
            handler(payload) {
                this.local_value = this.setLocalValue(payload)
                this.setDateValue()
                this.$emit('inputValue',
                    {
                        field: this.field,
                        text: this.local_value,
                        name: 'selectedRow'
                    }
                )
            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>